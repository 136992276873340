import React from "react";
import PropTypes from "prop-types";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.feature} className="column is-12">
        <div className="card">
          <div className="card-content">
            <p>{item.feature}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      feature: PropTypes.string
    })
  )
};

export default FeatureGrid;
