import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const ButtonStyle = css`
  display: inline-block;
  padding: 12px 16px 10px;
  font-size: 18px;
  font-size: 1rem;
  line-height: 1.25;
  background-color: #fff;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #279af1;
  text-align: center;
  -webkit-box-shadow: inset 0 0 0 2px #279af1;
  box-shadow: inset 0 0 0 2px #279af1;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
`;

export const Link = styled(GatsbyLink)`
  ${ButtonStyle}
`;

export const ExternalLink = styled.a`
  ${ButtonStyle}
`;
