import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";

import { ExternalLink } from "../components/Button";
import Youtube from "../components/Youtube";
import { useLanguageRedirect } from "../hooks";
import { SEO } from "../components/SEO";
import styled from "styled-components";

const BackgroundCover = styled.div`
  width: 100vw;
  height: 600px;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
  background-size: cover;
  background-attachment: fixed;
`;

export const IndexPageTemplate = ({
  page,
  language,
  image,
  heading,
  subheading,
  mainpitch,
  features,
  history,
  pricing,
}) => {
  useLanguageRedirect();

  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        url={language.includes("en") ? "/" : `/${language}`}
      />
      <BackgroundCover
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}
      >
        <div
          style={{
            display: "flex",
            lineHeight: "1",
            justifyContent: "space-around",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <h1
            className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              color: "white",
              lineHeight: "1",
              padding: "0.25em",
              paddingLeft: 25,
              paddingRight: 25,
            }}
          >
            {heading}
          </h1>
          <h3
            className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              color: "white",
              lineHeight: "1",
              padding: "0.25em",
              paddingLeft: 25,
              paddingRight: 25,
            }}
          >
            {subheading}
          </h3>
        </div>
      </BackgroundCover>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content" style={{ paddingBottom: 72 }}>
                    <div className="tile">
                      <h1 className="title">{mainpitch.question}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.answer}</h3>
                    </div>
                    <div className="has-text-centered" style={{ margin: 24 }}>
                      <Youtube videoId={mainpitch.promo} />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="is-size-2">{features.question}</h3>
                    </div>
                  </div>
                  <Features gridItems={features.answer} />
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <ExternalLink href="https://app.tonguetieapp.com">
                        {features.cta}
                      </ExternalLink>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <h2 className="is-size-2">{history.title}</h2>
                      <p className="is-size-5">{history.description}</p>
                      <p className="is-size-5">{history.scientific}</p>
                      <p className="is-size-5">{history.support}</p>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <h2 className="is-size-2">{pricing.title}</h2>
                      <p className="is-size-5">{pricing.description}</p>
                      <div className="column is-12 has-text-centered">
                        <ExternalLink href="https://app.tonguetieapp.com">
                          {pricing.cta}
                        </ExternalLink>
                      </div>
                      <p className="is-size-5 is-italic mt-4">
                        {pricing.conditions}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  language: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
    promo: PropTypes.string,
  }),
  features: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.array,
    cta: PropTypes.string,
  }),
  history: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    scientific: PropTypes.string,
    support: PropTypes.string,
  }),
  pricing: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.string,
    conditions: PropTypes.string,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout language={frontmatter.language}>
      <IndexPageTemplate
        language={frontmatter.language}
        page={frontmatter.page}
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        features={frontmatter.features}
        history={frontmatter.history}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        language
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page {
          title
          description
        }
        heading
        subheading
        mainpitch {
          question
          answer
          promo
        }
        features {
          question
          answer {
            feature
          }
          cta
        }
        history {
          title
          description
          scientific
          support
        }
        pricing {
          title
          description
          cta
          conditions
        }
      }
    }
  }
`;
