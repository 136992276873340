import React from "react";

export default ({ videoId, videoTitle }) => {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title={videoTitle}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
      />
    </div>
  );
};
